import React from "react";
import { useWindowSize } from "api/commonConstants";

const AdContainer = ({ divId = "" }) => {
  const size = useWindowSize();
  return (
    <div className="container-row py-2 text-center">
      <p
        style={{
          fontSize: "11px",
          color: "#b7b7b7",
          marginBottom: "0px",
        }}
      >
        Advertisement
      </p>
      <div
        id={`${divId}`}
        style={{
          minWidth: "300px",
          minHeight: size.width > 600 ? "100px" : "260px",
          display: "block !important",
        }}
      >
        <script
          type="text/javascript"
          defer
          dangerouslySetInnerHTML={{
            __html: `{googletag.cmd.push(function() { googletag.display('${divId}')})`,
          }}
        ></script>
      </div>
    </div>
  );
};

export default AdContainer;
