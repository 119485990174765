import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogsList } from "redux/actions/liveblogActions";
import { useRouter } from "next/router";

const ListingPage = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  useEffect(() => {
    const data = {
      appSlug: `${process.env.NEXT_PUBLIC_APP_SLUG}`,
      pageNumber: "1",
      pageSize: "10",
    };
    dispatch(getBlogsList(data));
  }, []);
  function getDate(input) {
    var inputDate = new Date(input);

    // Convert the Date object to the desired format
    var options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    var outputDateStr = inputDate.toLocaleDateString("en-US", options);
    return outputDateStr;
  }
  const blogsList = useSelector((data) => data.liveblogReducer?.blogsList);
  console.log("BlogsList ", blogsList);
  return (
    <>
      <div className="no-value">
        <div
          className="blog-list-header"
          style={{ marginLeft: "1.2rem", display: "flex" }}
        >
          <img
            src="/assets/images/quiz/live.png"
            width="25px"
            height="25px"
            style={{ marginTop: "4px", marginRight: "4px" }}
          ></img>
          <h2
            style={{ fontSize: "2.7rem", color: "#ce0808", fontWeight: "600" }}
          >
            Live Updates
          </h2>
        </div>
        <div
          className="blogs-list-main"
          style={{
            minHeight: "30.7rem",
            display: "flex",
            overflowX: "scroll",
            gap: "2rem",
            paddingLeft: "10px",
            paddingBottom: "10px",
          }}
        >
          {blogsList?.map((data, index) => (
            <div
              className="blog-list-element"
              key={`index_${index}_${data?._id}`}
              onClick={() => {
                router.push(`/liveblog/${data?.sefUrl}`);
              }}
            >
              <div className="list-author-box">
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "space-evenly",
                    gap: "2rem",
                  }}
                >
                  <img
                    className="blog-author-img"
                    src={
                      data?.postedBy?.profileImage !== ""
                        ? data?.postedBy?.profileImage
                        : "https://static.thelallantop.com/images/author/1692252102022_jyoti.webp"
                    }
                    width="40px"
                    height="40px"
                  ></img>
                  <div style={{ borderTop: "2px " }}>
                    <div className="author-name">
                      {data?.postedBy?.firstname} {data?.postedBy?.lastname}
                    </div>
                    {/* <div style={{ display: "flex" }}>
                    {updatedTime.split(" ").slice(0, 2).join(" ")}{" "}
                    <div className="pink-small-dot"></div>{" "}
                    {updatedTime.split(" ").slice(2).join(" ")}
                  </div> */}
                    <div>{getDate(data?.updatedAt)}</div>
                  </div>
                  {/* <div>
                  <img
                    style={{ marginBottom: "5px" }}
                    src="/assets/images/share2.png"
                    width="12px"
                  ></img>
                </div> */}
                </div>
              </div>
              <img
                style={{ padding: "8px", borderRadius: "20px" }}
                src={data?.thumbnail}
                height="160px"
              ></img>
              <div style={{ padding: "0px 12px" }}>
                <h5>
                  {/* <span style={{ color: "#ce0808" }}>
                  <img src="/assets/images/quiz/live.png"></img>Live:
                </span> */}
                  {data?.eventStatus == "live" && (
                    <span style={{ color: "#ce0808" }}>
                      <img
                        src="/assets/images/live_gif.gif"
                        width={"20px"}
                        height="20px"
                      ></img>
                      Live :{" "}
                    </span>
                  )}
                  {data?.title?.split(" ").slice(0, 10).join(" ") + "..."}
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="no-value-desktop">
        <div
          className="blog-list-header"
          style={{ marginLeft: "1.2rem", display: "flex" }}
        >
          <img
            src="/assets/images/live_gif.gif"
            width="25px"
            height="25px"
            style={{ marginTop: "8px", marginRight: "4px" }}
          ></img>
          <h2
            style={{ fontSize: "2.7rem", color: "#ce0808", fontWeight: "600" }}
          >
            Live Updates
          </h2>
        </div>
        <div
          className="blogs-list-main"
          style={{
            minHeight: "30.7rem",
            display: "flex",
            overflowX: "scroll",
            gap: "2rem",
            paddingLeft: "10px",
            paddingBottom: "10px",
          }}
        >
          {blogsList?.map((data, index) => (
            <div
              className="blog-list-element-desktop"
              key={`index_${index}_${data?._id}`}
              onClick={() => {
                router.push(`/liveblog/${data?.sefUrl}`);
              }}
            >
              <img
                style={{ padding: "8px", borderRadius: "20px" }}
                src={data?.thumbnail}
              ></img>
              <div className="list-author-box">
                <div>{getDate(data?.updatedAt)}</div>
              </div>
              <div style={{ padding: "0px 12px" }}>
                <h5>
                  {data?.eventStatus == "live" && (
                    <span style={{ color: "#ce0808" }}>
                      <img
                        src="/assets/images/live_gif.gif"
                        width={"20px"}
                        height="20px"
                      ></img>
                      Live :{" "}
                    </span>
                  )}
                  {data?.title?.split(" ").slice(0, 15).join(" ") + "..."}
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ListingPage;
