// import { Carousel } from "react-bootstrap";
// import Link from "next/link";
//import OptionDropdown from "components/category/about/optionDropdown/optionDropdown";
import { useSelector } from "react-redux";
// import { postLike } from "redux/actions/postAction";
import React, { useEffect, useState } from "react";
// import { useAmp } from "next/amp";
// import CustomPopup from "components/shared/modal/customPopup";
// import { useSession } from "next-auth/react";
// import { getLanguage } from "localization/i8intl";
import { pushGoogleTabEvent, createHomeArticlePayload } from "api/googletab";
// import ReactPlaceholder from 'react-placeholder';
// import "react-placeholder/lib/reactPlaceholder.css";
import { useRouter } from "next/router";

const NewsImageTextBanner1 = ({ postData, hTag }) => {
  console.log("NewsImageTextBanner1", postData);
  //const sessionData = JSON.parse(localStorage.getItem("lallantopSession2"));
  const router = useRouter();
  const [imageSize, setImageSize] = useState(200);

  const { query } = router;
  // const { data: session } = useSession();
  // const { userLocalDetail } = useSelector(({ AuthReducer }) => AuthReducer);
  // const [loginPrompt, setLoginPrompt] = useState(false);
  // const [videoDuration, setVideoDuration] = useState(0);
  const [postStateData, setPostStateData] = useState(postData);
  // const [openShareModal, setOpenShareModal] = useState(false);
  //const [shareData, setShareData] = useState({});
  const { postDetails } = useSelector(({ PostReducer }) => PostReducer);
  const isHomePath = router.asPath === "/";
  const pageToOpen = `/${
    postStateData?.categorySlug?.toLowerCase() || query?.categoryName
  }/post/${postStateData?.postSlug || ""}`;

  const ImageUrl =
    "data:image/webp;base64,UklGRmQCAABXRUJQVlA4WAoAAAAgAAAAxwAAxwAASUNDUMgBAAAAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADZWUDggdgAAAFAMAJ0BKsgAyAA/zebxc7+4M6ygSAPwOYlpbuF2sRtAE9r0VcIMghqqTXbaLhBkENVSa7bRcIMghqqTXbaLhBkENVSa7bRcIMghqqTXbaLhBkENVSa7bRcIMghqqTXbaLhBkENVSa7bRZwAAP7+yOAAAAAAAAA=";

  // const shareUrl = `${pageToOpen}?postId=${postStateData?._id}`;
  useEffect(() => {
    if (postDetails?._id === postData?._id) {
      setPostStateData(postDetails);
    }
  }, [postDetails, postData?._id]);

  const [firstScroll, setFirstScroll] = useState(false);

  useEffect(() => {
    if (firstScroll === false) {
      function handleScroll() {
        setFirstScroll(true);
        window.removeEventListener("scroll", handleScroll);
      }

      window.addEventListener("scroll", handleScroll);
    }
  }, [firstScroll]);
  useEffect(() => {
    let timer = setTimeout(() => {
      setFirstScroll(true);
    }, 18000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <div className="headline-box1">
        <div className="headline-left-col" style={{ position: "relative" }}>
          {postData.type === "liveblog" &&
            postData?.liveBlogData?.eventStatus === "live" && (
              <img
                src="assets/images/giphy.gif"
                style={{
                  position: "absolute",
                  width: "80px",
                  zIndex: "99",
                  height: "80px",
                  right: "0",
                  top: "-10px",
                }}
                alt="live"
              ></img>
            )}{" "}
          <div className="headline-pic-box ">
            <a
              href={`${
                  postData.eventStatus === undefined
                    ? pageToOpen
                    : `/business/post/${postData?.sefUrl}`
              
              }`}
              passHref
            >
              <a
                className="headline-thumb"
                onClick={() => {
                  //console.log(isHomePath, "home path");
                  if (isHomePath) {
                    pushGoogleTabEvent(
                      "lt_cards",
                      createHomeArticlePayload(postStateData, {
                        Section: "Home",
                      })
                    );
                    // ClevertapReact.event(
                    //   "homepage_card",
                    //   createHomeCardPayload("homepage_card", postStateData)
                    // );
                  } else if (router.asPath === "/video") {
                    //console.log("bdeio");
                    pushGoogleTabEvent(
                      "lt_cards",
                      createHomeArticlePayload(postStateData, {
                        Section: "Video",
                      })
                    );
                  }
                }}
              >
                {/* <img
                src={postStateData?.headingImageUrl}
                alt={postStateData?.imgAltText || "News pic"}
              /> */}
                {postData?.headingImageUrl && (
                  <img
                    // className={!firstScroll ? "shimmer-effect" : ""}
                    src={
                      firstScroll
                        ? postData?.headingImageUrl + `?width=360`
                        : postData?.headingImageUrl + `?width=70`
                    }
                    alt={postData?.imgAltText || "News pic"}
                    // loading="lazy"
                    fetchpriority="high"
                    // eslint-disable-next-line react/no-unknown-property
                  />
                )}
                {postData?.thumbnail && (
                  <img
                    // className={!firstScroll ? "shimmer-effect" : ""}
                    src={
                      firstScroll
                        ? postData?.thumbnail + `?width=360`
                        : postData?.thumbnail + `?width=70`
                    }
                    alt={postData?.imgAltText || "News pic"}
                    loading="lazy"
                    // fetchpriority="high"
                    // eslint-disable-next-line react/no-unknown-property
                  />
                )}

                <div className="news-type-box">
                  {
                    <i
                      className={`icon-${
                        postStateData?.type === "text" ||
                        postData.type === "liveblog"
                          ? "cat-read"
                          : postStateData?.type === "audio"
                          ? "mic"
                          : "play-circle"
                      }`}
                    ></i>
                  }
                  {postStateData?.type === "audio" && (
                    <span>{videoDuration}</span>
                  )}
                </div>
              </a>
            </a>
            {/* <div className="news-type-box">
            <i
              className={`icon-${
                postStateData?.type === "text"
                  ? "cat-read"
                  : postStateData?.type === "audio"
                  ? "mic"
                  : "play-circle"
              }`}
            ></i>
            {postStateData?.type === "audio" && <span>{videoDuration}</span>}
          </div> */}
          </div>
          <div className="headline-right-col" style={{ position: "relative" }}>
            <div className="headline-body">
              <div
                className="headline-info"
                style={{ paddingTop: "0.5rem", height: "6rem" }}
              >
                {hTag === 1 ? (
                  <h1 style={{ marginBottom: "0rem", cursor: "pointer" }}>
                    <a
                      href={
                      
                          postData.eventStatus === undefined
                            ? pageToOpen
                            : `/business/post/${postData?.sefUrl}`
                        // query: {
                        //   categoryName: postStateData?.name,
                        //   postSlug: postStateData?.postSlug,
                        // },
                      }
                      passHref
                    >
                      <a
                        style={{
                          fontFamily:
                            "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                          fontStyle: "normal",
                          fontWeight: "550",
                          fontSize: "18px",
                          lineHeight: "135%",
                          color: "#000000",
                        }}
                      >
                        {postData?.title?.padEnd(88)}
                      </a>
                    </a>
                  </h1>
                ) : hTag === 2 ? (
                  <h2 style={{ marginBottom: "0rem", cursor: "pointer" }}>
                    <a
                      href={
                          postData.eventStatus === undefined
                            ? pageToOpen
                            : `/business/post/${postData?.sefUrl}`
                        // query: {
                        //   categoryName: postStateData?.name,
                        //   postSlug: postStateData?.postSlug,
                        // },
                      }
                      passHref
                    >
                      <a
                        style={{
                          fontFamily:
                            "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                          fontStyle: "normal",
                          fontWeight: "550",
                          fontSize: "18px",
                          lineHeight: "135%",
                          color: "#000000",
                        }}
                      >
                        {postData?.title?.padEnd(88)}
                      </a>
                    </a>
                  </h2>
                ) : (
                  <h3 style={{ marginBottom: "0rem", cursor: "pointer" }}>
                    <a
                      href={
                     
                          postData.eventStatus === undefined
                            ? pageToOpen
                            : `/business/post/${postData?.sefUrl}`
                        // query: {
                        //   categoryName: postStateData?.name,
                        //   postSlug: postStateData?.postSlug,
                        // },
                      }
                      passHref
                    >
                      <a
                        style={{
                          fontFamily:
                            "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                          fontStyle: "normal",
                          fontWeight: "550",
                          fontSize: "18px",
                          lineHeight: "135%",
                          color: "#000000",
                        }}
                      >
                        {postData?.title?.padEnd(88)}
                      </a>
                    </a>
                  </h3>
                )}
                {/* <p>{postStateData?.shortText?.substring(0, 75).concat("...")}</p> */}
              </div>
              {/* <div className="published-date">
              <span
                className="pre-span"
                style={{
                  fontFamily: "Hind",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "26px",
                  color: "#999999",
                }}
              >
                {postData?.publishedAt
                  ? timeSince(postData?.publishedAt)
                  : timeSince(postData?.createdAt)}{" "}
                ago
              </span>
            </div> */}
              <div
                className="line-newsImageTextBanner1"
                style={{
                  height: "1px",
                  background: "rgba(166, 166, 166, 0.2)",
                  width: "100%",
                }}
              ></div>
              <div></div>
              {/* <div className="more-col">
            <OptionDropdown
              postData={postStateData}
              showLoginPrompt={() => setLoginPrompt(true)}
              shareUrl={shareUrl}
            />
          </div> */}
            </div>
            {/* <div
            className="headline-footer-section"
            style={{ marginTop: "30px" }}
          >
            <div className="published-date">
              <span className="pre-span">
                {postData?.publishedAt
                  ? timeSince(postData?.publishedAt)
                  : timeSince(postData?.createdAt)}{" "}
                ago
              </span>
            </div>
          </div> */}

            {/* <div className="three-dot-headline-menu">
            <img
              className="arrow-down dots"
              src="/assets/images/moreMenuVertical.png"
              width="20"
              height="20"
              alt=""
            />
          </div> */}
          </div>
        </div>

        {/* <CustomPopup
        showModal={loginPrompt}
        onClose={() => setLoginPrompt(false)}
      /> */}
        {/* <Modal
        show={openShareModal}
        fullscreen={false}
        centered
        size="sm"
        onHide={() => setOpenShareModal(false)}
      >
        <div className="row p-2">
          <div className="col-6">Social Share</div>
          <div className="col-6 text-end">
            <div role={"button"} onClick={() => setOpenShareModal(false)}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div align="center">
            <FacebookShareButton quote={shareData.text} url={shareData.url}>
              <FacebookIcon className="me-1" size={32} round />
            </FacebookShareButton>

            <TwitterShareButton title={shareData.text} url={shareData.url}>
              <TwitterIcon className="me-1" size={32} round />
            </TwitterShareButton>

            <EmailShareButton
              url={shareData.url}
              subject={shareData.text}
              body={shareData.url}
            >
              <EmailIcon className="me-1" size={32} round />
            </EmailShareButton>

            <LinkedinShareButton title={shareData.text} url={shareData.url}>
              <LinkedinIcon className="me-1" size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareData.url} title={shareData.text}>
              <WhatsappIcon className="me-1" size={32} round />
            </WhatsappShareButton>
            <hr />
            <span style={{ fontSize: "13px" }} className="text-dark">
              <span id="cpy">Copy</span> to Clipboard{" "}
              <i className="las la-clipboard la-lg"></i>
            </span>
            <input
              style={{ fontSize: "13px" }}
              onClick={() => {
                let et = document.getElementById("copy");
                et.select();
                document.execCommand("copy");
                document.getElementById("cpy").textContent = "Copied";
              }}
              id="copy"
              value={shareData.url}
              type="text"
              readOnly
              className="form-control"
            />
          </div>
        </Modal.Body>
      </Modal> */}
      </div>
      <style>
        {`.shimmer-effect {
         color: grey;
         display: inline-block;
         /* adding gradients */
         -webkit-mask: linear-gradient(120deg, #000 25%, #0005, #000 75%) right/250% 100%;
         /* shimmer effect animation */
         animation: shimmer 1.5s infinite;
         background-repeat: no-repeat;
         width: 500px;
      }
      @keyframes shimmer {
         100% {
            /* setting up mask position at left side */
            -webkit-mask-position: left
         }
      }`}
      </style>
    </>
  );
};
export default NewsImageTextBanner1;
