import React, { useState,useEffect } from "react";
// import Link from "next/link";
import FtafatModal from "components/homePage/goodMorning/ftafatModal";
//import { useSelector } from "react-redux";
// import { useSession } from "next-auth/react";
// import { getLanguage } from "localization/i8intl";
// import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { pushGoogleTabEvent } from "api/googletab";
import { useWindowSize } from "api/commonConstants";
import { baseUrl } from "api/constants";

const GoodMorning = () => {
  const size = useWindowSize();
  const router = useRouter();

  const [fatafatPosts, setFatafatPosts] = useState([]);

  const [shouldShow, setShouldShow] = useState(false);
  const queryIndex = router.query?.index ? parseInt(router.query.index) : 0;
  const [firstScroll, setFirstScroll] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setFirstScroll(true)
      window.removeEventListener("scroll", handleScroll);
    }

    window.addEventListener("scroll", handleScroll);
  
  }, []);


  const handleClick = () => {
    pushGoogleTabEvent("homepage_fatafat_open", {});
    setShouldShow(false);
    router.push("/");
    // setShow((e) => !e);
  };
  const openHandler = () => {
    if (fatafatPosts && fatafatPosts?.length > 0) {
      setShouldShow(true);
      router.push(
        `?fatafat=${fatafatPosts[0]?.postSlug}&index=0`,
        `/${fatafatPosts[0]?.categorySlug}/post/${fatafatPosts[0]?.postSlug}`,
        { shallow: true }
      );
    } else {
      fatafatHandler();
      setShouldShow(true);
    }
  };

  const fatafatHandler = () => {
    fetch(`${baseUrl}/post/allFatafat?limit=9&skip=1&type=text`)
      .then((res) => res.json())
      .then((d) => {
        setFatafatPosts(d?.result?.entities);
      });
  };

  return (
    <>
      <div onClick={openHandler}>
          <img
            src={`https://static.thelallantop.com/images/post/1690877763030_fatafat-jpg.webp?&quality=70&width=${firstScroll ? 500 : 100}`}
            className="bannerImage fatafatGif"
            fetchpriority="high"
            alt="banner"
          />
{/*      
          <img
            src="https://static.thelallantop.com/images/post/1690877763030_fatafat-jpg.webp"
            className="bannerImage fatafatGif"
            alt="banner"
            fetchpriority="high"
          /> */}
      </div>
      {fatafatPosts && fatafatPosts.length > 0 && shouldShow && (
        <FtafatModal
          show={shouldShow}
          fatafatPosts1={fatafatPosts}
          onClick={() => {
            handleClick();
          }}
          queryIndex={queryIndex}
        />
      )}
    </>
  );
};
export default GoodMorning;
