import React from "react";

const TaboolaAdContainer = () => {
  return (
    <div id="taboola-ads" className="content-wrapper">
      <div id="taboola-below-homepage-thumbnails"></div>
    </div>
  );
};

export default TaboolaAdContainer;
