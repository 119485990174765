// import { useEffect, useState } from "react";
// import { baseUrl } from "api/constants";
// import StoriesSwiper1 from "../stories-slider/carousel/swiper/storiesSwiper1";
// import { useWindowSize } from 'api/commonConstants';
import VisualStoriesHomeWidget from "./visualStoriesHomeWidget";

const VisualStories = ({
  responseVs,
  liveBlogWidgetData,
  atOlympicsPage = false,
}) => {
  // const [storyShowData, setVisualStoriesData] = useState([]);
  // const [loginPrompt, setLoginPrompt] = useState(false);
  // const size = useWindowSize();
  // const responsive = {
  //   superLargeDesktop: {
  //     breakpoint: { max: 4000, min: 3001 },
  //     items: 8.2,
  //     slidesToSlide: 8,
  //   },
  //   largeDesktop: {
  //     breakpoint: { max: 3000, min: 2001 },
  //     items: 7.2,
  //     slidesToSlide: 7,
  //   },
  //   desktop: {
  //     breakpoint: { max: 2000, min: 1600 },
  //     items: 6.2,
  //     slidesToSlide: 6,
  //   },
  //   laptop: {
  //     breakpoint: { max: 1599, min: 1201 },
  //     items: 5.2,
  //     slidesToSlide: 5,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1200, min: 992 },
  //     items: 3,
  //     slidesToSlide: 3,
  //   },
  //   mobile: {
  //     breakpoint: { max: 991, min: 500 },
  //     items: 2.1,
  //     slidesToSlide: 2,
  //   },
  //   smallMobile: {
  //     breakpoint: { max: 500, min: 426 },
  //     items: 2.1,
  //     slidesToSlide: 2,
  //   },
  //   verySmallMobile: {
  //     breakpoint: { max: 425, min: 0 },
  //     items: 2.1,
  //     slidesToSlide: 2,
  //   },
  // };
  // console.log("Inside 4");

  // useEffect(() => {
  //   fetchVisualStories();
  // }, []);
  // const fetchVisualStories = async () => {
  //   let data = {
  //     limit: 10,
  //     skip: 1,
  //     categories: [],
  //   };
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   };
  //   // console.log("Fetchcalled");
  //   await fetch(`${baseUrl}/story/filter`, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setVisualStoriesData(data?.result?.stories);
  //     });
  // };
  return (
    <VisualStoriesHomeWidget
      visualStoryData={responseVs}
      liveBlogWidgetData={liveBlogWidgetData}
      atOlympicsPage={atOlympicsPage}
    />
    // <div className="outer-containers">
    //   <div className="content-container vs-iframe">
    //     <a href="/visualstories" className="read-more-vs">
    //       <span className="text">और देखेंं</span>
    //       <i
    //         className="icon-arrow-right"
    //         style={{
    //           color: "gray",
    //         }}
    //       ></i>{" "}
    //     </a>
    //     <iframe
    //       id="myIframe"
    //       className="responsive-iframe22"
    //       src="https://www.thelallantop.com/visualstories/visualstories/widget/visualstoriesWidget/visual-lallantop-00765Hg/?theme=1"
    //       width="100%"
    //       height="480px"
    //     ></iframe>
    //   </div>
    // </div>
  );
};

export default VisualStories;
